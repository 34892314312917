import CustomFilters from "@/config/filters"
import utilities from "@/config/util"

export default {
  install(app) {
    app.config.globalProperties.$customFilters = CustomFilters // could just be imported in the component
    app.config.globalProperties.$util = utilities // could just be imported in the component
  },
}

declare module "vue" {
  interface ComponentCustomProperties {
    $customFilters: typeof CustomFilters
    $util: typeof utilities
  }
}
